.login__reset-password-link {
    text-align: center;
    display: block;
    margin: var(--spacing-24) auto 0;
    font: var(--font-sans-caption-16-medium);
    width: max-content;

    outline: none;
    text-decoration-color: var(--color-background-glassy-b);
    color: var(--color-content-primary);
}

.login__reset-password-link:hover {
    text-decoration-color: inherit;
}
