.login-reset-password__text-block {
    text-align: center;
    margin-bottom: var(--spacing-24);
}

.login-reset-password__back-to-login {
    text-decoration: underline;
    margin-top: 24px;
    font: var(--font-sans-body-16-medium-ui);
    text-align: center;
    cursor: pointer;
    text-decoration-color: var(--color-background-glassy-b);
}

.login-reset-password__back-to-login:hover {
    text-decoration-color: inherit;
}

.login-reset-password__title {
    color: var(--color-content-primary);
    font: var(--font-sans-body-16-regular-paragraph);
}

.login-reset-password__title_email {
    font: var(--font-sans-body-16-medium-paragraph);
    overflow-wrap: break-word;
}
