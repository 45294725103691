.support-chat__trigger {
    position: fixed;
    bottom: var(--spacing-16);
    right: var(--spacing-16);

    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    appearance: none;
    border: none;
    padding: 0;
    border-radius: var(--border-radius-10);

    cursor: pointer;

    background: transparent;
}

/* var(--media-mobile-l) */
@media (max-width: 539px) {
    .support-chat__trigger {
        background: var(--color-palette-white-1000);
        box-shadow: 0px 1px 3px 0px #00000014;
    }
}
