.drawer__curtain {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
    width: 100%;
    height: 85%;
}

.drawer__curtain:before {
    --handler-height: 3px;

    display: block;
    position: absolute;
    top: calc(var(--spacing-8) * -1 - var(--handler-height));
    left: 50%;
    transform: translateX(-50%);
    transition: opacity var(--transition-timing) var(--transition-easing);
    opacity: 0;
    border-radius: var(--border-radius-6);
    background: var(--color-background-default);
    width: 40px;
    height: var(--handler-height);
    content: '';
    pointer-events: none;
}

.drawer__curtain.enter,
.drawer__curtain.enter-active,
.drawer__curtain.exit,
.drawer__curtain.exit-active {
    transition: transform var(--transition-timing) var(--transition-easing),
        opacity var(--transition-timing) var(--transition-easing);
}
