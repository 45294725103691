.drawer__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0%;
    touch-action: none;
}

.drawer__backdrop.enter,
.drawer__backdrop.enter-active,
.drawer__backdrop.exit,
.drawer__backdrop.exit-active {
    transition: opacity var(--transition-timing) var(--transition-easing);
}
