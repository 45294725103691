.register__privacy-policy-link {
    font: var(--font-sans-body-16-medium-ui);

    color: var(--color-content-primary);
    text-decoration-color: var(--color-background-glassy-b);
}

.register__privacy-policy-link:hover {
    color: var(--color-content-primary);
    text-decoration-color: inherit;
}

.register__privacy-policy-label-children-wrapper {
    white-space: normal;
}

.register__tip-after-reddirect {
    text-align: center;

    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-32);

    font: var(--font-sans-body-16-regular-ui);
    color: var(--color-content-secondary);
}