.input-wrapper:not(:first-child, .input-wrapper_type_hidden) {
    margin-top: var(--spacing-8);
}

.input-wrapper_type_submit:not(:first-child) {
    margin-top: var(--spacing-16);
}

.input-wrapper_hidden {
    display: none;
}

.input__control-wrapper {
    position: relative;
}

input {
    outline: none;
}

.input_type_text,
.input_type_email,
.input_type_password,
.input_type_submit {
    width: 100%;
    height: var(--spacing-48);

    padding: 21px 40px 5px 15px;

    font: var(--font-sans-body-16-medium-ui);

    border: 1px solid var(--color-background-glassy-b);
    border-radius: var(--border-radius-12);
}

.input_type_email:focus,
.input_type_text:focus,
.input_type_password:focus {
    padding: 20px 39px 4px 14px;
    border: 2px solid var(--color-accent-neutral);
}

.input_type_submit:focus {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.16);
}

.input__message_error {
    margin-top: var(--spacing-4);

    color: var(--color-accent-critical);
    font: var(--font-sans-caption-14-regular);
}

.input__error-icon {
    --icon-size: 24px;

    position: absolute;
    top: var(--spacing-12);
    left: calc(100% - var(--spacing-16) - var(--icon-size));

    color: var(--color-accent-critical);
}

.input__label {
    position: absolute;

    top: 14px;
    left: var(--spacing-16);

    transition: 0.2s;
    transform-origin: left center;

    pointer-events: none;

    white-space: nowrap;

    color: var(--color-content-secondary);

    font: var(--font-sans-body-16-regular-ui)
}

.input_type_email:not(.input_empty) ~ .input__label,
.input_type_text:not(.input_empty) ~ .input__label,
.input_type_password:not(.input_empty) ~ .input__label,
.input_type_email:focus ~ .input__label,
.input_type_text:focus ~ .input__label,
.input_type_password:focus ~ .input__label {
    transform: translate(0, -9px) scale(0.75);
}

.input_type_submit {
    padding: 0;
    color: var(--color-palette-black-300-a);
    text-align: center;
    background-color: var(--color-palette-black-100-a);

    transition: transform 0.1s ease-in-out, background-color 0.15s ease-in-out;

    border-width: 0;
    border-radius: var(--border-radius-12);
}

form:valid .input_type_submit:active {
    transform: scale(0.96);
}

form:valid .input_type_submit:not(:disabled) {
    color: var(--color-content-inverted-primary);
    background-color: var(--color-accent-neutral);
    cursor: pointer;
}

form:valid .input_type_submit:not(:disabled).input_type_submit:hover {
    background-color: var(--color-palette-black-1000);
}

.input__message-slidedown {
    overflow: hidden;

    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 0.3s;
}

.input__icon-eye {
    --icon-size: 24px;

    position: absolute;
    top: var(--spacing-12);
    left: calc(100% - var(--spacing-16) - var(--icon-size));

    cursor: pointer;
}
