.open-gmail-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px var(--spacing-16);
    text-decoration: none;
    border-radius: var(--border-radius-12);
    border-bottom: 2px solid var(--color-background-glassy-b);
    background: var(--color-accent-neutral);
    color: var(--color-content-inverted-primary);
    font: var(--font-sans-body-16-medium-ui);
    cursor: pointer;
}

.open-gmail-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.16);
}
