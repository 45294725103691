.login-config-totp__text-block {
    text-align: center;
}

.login-config-totp__prompt {
    font: var(--font-sans-body-16-regular-paragraph);
    margin-bottom: var(--spacing-8);
    color: var(--color-content-primary);
}

.login-config-totp__note {
    font: var(--font-sans-body-16-regular-paragraph);
    white-space: break-spaces;
    color: var(--color-content-secondary);
}

.login-config-totp__prompt-code {
    color: var(--color-content-primary);
    font: var(--font-sans-body-16-regular-paragraph);
    margin-top: var(--spacing-48);
    margin-bottom: var(--spacing-16);
}

.login-config-totp__action-link {
    font: var(--font-sans-body-16-medium-ui);
    white-space: break-spaces;
    color: var(--color-content-primary);
}

.login-config-totp__qr-code {
    display: block;
    /* QR code size is 160px, but keycloak adds white margins
    and the code is 80% of the total size */
    max-width: 200px;
    max-height: 200px;
    margin: var(--spacing-4) auto;

    background-color: white;
    mix-blend-mode: multiply;
}

.login-config-totp__setup-code-block {
    display: flex;
    padding: var(--spacing-24) var(--spacing-16);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-16);
    align-self: stretch;
    margin-top: var(--spacing-24);
    margin-bottom: var(--spacing-24);

    border-radius: var(--border-radius-14);
    background: var(--color-background-default);

    /* Elevation/S */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 0 0.5px 0 rgba(0, 0, 0, 0.30);
}

.login-config-totp__setup-code {
    color: var(--color-content-primary);
    text-align: center;

    font: var(--font-sans-body-16-regular-paragraph);
    font-feature-settings: 'clig' off, 'liga' off;
}

.login-config-totp__separator {
    height: 1px;
    align-self: stretch;

    background-color: var(--color-background-glassy-a);
}

.login-config-totp__copy-button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8);
    cursor: pointer;

    color: var(--color-content-primary);
    font: var(--font-sans-body-16-medium-ui);

    transition: color 0.1s linear;
}

.login-config-totp__copy-button:hover {
    color: var(--color-content-secondary);
}
