.form {
    width: 100%;
    max-width: 336px;

    font: var(--font-sans-body-16-medium-ui);
    color: var(--color-content-primary);

    display: flex;
    flex-direction: column;
}

.form__title {
    margin: 0 0 var(--spacing-32);
    font: var(--font-sans-header-40-default);
    color: var(--color-content-primary);
    text-align: center;
}

.form__header {
    text-align: center;
}

.form__header-title {
    width: 124px;
    height: 28px;
}

.form__content:not(:last-child) {
    margin-bottom: var(--spacing-24);
}

.form__social-block {
    margin-top: var(--spacing-32);
}

.form__message, .form__note {
    font: var(--font-sans-body-16-regular-paragraph);
    margin: 0 0 var(--spacing-24);
    text-align: center;
}

.form__message {
    color: var(--color-content-primary);
}

.form__note {
    color: var(--color-content-secondary);
    white-space: break-spaces;
}
