.social-buttons {
    padding-top: var(--spacing-24);
    border-top: 1px solid var(--color-background-glassy-a);
}

.social-buttons__item,
.social-buttons__item-wrapper {
    display: flex;
    align-items: center;
}

.social-buttons__item {
    justify-content: center;

    height: var(--spacing-48);

    color: var(--color-content-primary);
    background-color: var(--color-background-default);

    border-radius: var(--border-radius-12);
    border: 1px solid var(--color-background-glassy-b);
    text-decoration: none;
}

.social-buttons__item:hover {
    background-color: var(--color-background-glassy-a);
}

.social-buttons__item-icon {
    margin-right: var(--spacing-8);
}

.social-buttons__item:not(:last-child) {
    margin-bottom: var(--spacing-8);
}

.social-buttons__item:focus {
    outline: none;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.16);
}
