/*
	prisma design system
	This file is automatically generated
	Do not modify this file — your changes will be erased
*/

/* stylelint-disable no-duplicate-selectors */

@custom-media --media-mobile-s (max-width: 359px);
@custom-media --media-mobile-m (max-width: 413px);
@custom-media --media-mobile-l (max-width: 539px);
@custom-media --media-tablet-s (max-width: 767px);
@custom-media --media-tablet-m (max-width: 1023px);
@custom-media --media-desktop-s (max-width: 1279px);
@custom-media --media-desktop-m (max-width: 1439px);
@custom-media --media-desktop-l (max-width: 1559px);
@custom-media --media-desktop-xl (max-width: 1919px);

.prisma {
    --border-radius-6: 6px;
    --border-radius-8: 8px;
    --border-radius-10: 10px;
    --border-radius-12: 12px;
    --border-radius-14: 14px;
    --border-radius-16: 16px;
    --border-radius-20: 20px;
}

.prisma {
    --shadow-small-default: 0px 1px 4px 1px rgba(36, 36, 36, 0.07);
    --shadow-small-hovered: 0px 1px 16px 2px rgba(36, 36, 36, 0.07);
    --shadow-small-pressed: 0px 0px 6px rgba(36, 36, 36, 0.07);
    --shadow-small-hidden: 0px 1px 4px 1px rgba(36, 36, 36, 0);
    --shadow-medium-default: 0px 1px 12px 2px rgba(36, 36, 36, 0.07);
    --shadow-medium-hovered: 0px 2px 32px 6px rgba(36, 36, 36, 0.07);
    --shadow-medium-pressed: 0px 1px 8px rgba(36, 36, 36, 0.07);
    --shadow-medium-hidden: 0px 1px 12px 2px rgba(36, 36, 36, 0);
    --shadow-huge-default: 0px 2px 160px 16px rgba(36, 36, 36, 0.07);
    --shadow-huge-hidden: 0px 2px 160px 16px rgba(36, 36, 36, 0);
}

.prisma {
    font-feature-settings: 'pnum' on, 'lnum' on;

    --font-mono-caption-14-regular: 400 0.875rem/1.5714em Suisse Intl Mono, monospace; /* 14px 400 */
    --font-mono-caption-16-regular: 400 1rem/1.5em Suisse Intl Mono, monospace; /* 16px 400 */
    --font-sans-body-16-medium-paragraph: 500 1rem/1.375em Suisse Intl, sans-serif; /* 16px 500 */
    --font-sans-body-16-medium-ui: 500 1rem/1.25em Suisse Intl, sans-serif; /* 16px 500 */
    --font-sans-body-16-regular-paragraph: 400 1rem/1.375em Suisse Intl, sans-serif; /* 16px 400 */
    --font-sans-body-16-regular-ui: 400 1rem/1.25em Suisse Intl, sans-serif; /* 16px 400 */
    --font-sans-body-18-medium-paragraph: 500 1.125rem/1.4444em Suisse Intl, sans-serif; /* 18px 500 */
    --font-sans-body-18-medium-ui: 500 1.125rem/1.2222em Suisse Intl, sans-serif; /* 18px 500 */
    --font-sans-body-18-regular-paragraph: 400 1.125rem/1.4444em Suisse Intl, sans-serif; /* 18px 400 */
    --font-sans-body-18-regular-ui: 400 1.125rem/1.2222em Suisse Intl, sans-serif; /* 18px 400 */
    --font-sans-caption-12-medium: 500 0.75rem/1.3333em Suisse Intl, sans-serif; /* 12px 500 */
    --font-sans-caption-12-regular: 400 0.75rem/1.3333em Suisse Intl, sans-serif; /* 12px 400 */
    --font-sans-caption-14-medium: 500 0.875rem/1.2857em Suisse Intl, sans-serif; /* 14px 500 */
    --font-sans-caption-14-regular: 400 0.875rem/1.2857em Suisse Intl, sans-serif; /* 14px 400 */
    --content-tertiary: 400 0.875rem/1.2857em Suisse Intl, sans-serif; /* 14px 400 */
    --font-sans-header-20-regular: 400 1.25rem/1.2em Suisse Intl, sans-serif; /* 20px 400 */
    --font-sans-header-20-medium: 500 1.25rem/1.2em Suisse Intl, sans-serif; /* 20px 500 */
    --font-sans-header-24-regular: 400 1.5rem/1.1667em Suisse Intl, sans-serif; /* 24px 400 */
    --font-sans-header-24-medium: 500 1.5rem/1.1667em Suisse Intl, sans-serif; /* 24px 500 */
    --font-sans-header-28-default: 500 1.75rem/1.1429em Suisse Intl, sans-serif; /* 28px 500 */
    --font-sans-header-32-default: 700 2rem/1em Gramatika, sans-serif; /* 32px */
    --font-sans-header-40-default: 700 2.5rem/1em Gramatika, sans-serif; /* 40px */
    --font-sans-header-48-default: 700 3rem/1em Gramatika, sans-serif; /* 48px */
    --font-sans-header-56-default: 700 3.5rem/1em Gramatika, sans-serif; /* 56px */
    --font-sans-header-64-default: 700 4rem/0.9375em Gramatika, sans-serif; /* 64px */
    --font-sans-header-72-default: 700 4.5rem/0.9444em Gramatika, sans-serif; /* 72px */
    --font-sans-header-88-default: 700 5.5rem/0.9545em Gramatika, sans-serif; /* 88px */
    --font-sans-header-120-default: 700 7.5rem/0.9333em Gramatika, sans-serif; /* 120px */
}

.prisma {
    --color-palette-black-100-a: rgba(0, 0, 0, 0.06);
    --color-palette-black-1000: #000000;
    --color-palette-black-100: #f0f0f0;
    --color-palette-black-200-a: rgba(0, 0, 0, 0.12);
    --color-palette-black-200: #e0e0e0;
    --color-palette-black-300-a: rgba(0, 0, 0, 0.25);
    --color-palette-black-300: #bfbfbf;
    --color-palette-black-500-a: rgba(0, 0, 0, 0.5);
    --color-palette-black-500: #808080;
    --color-palette-black-50: #f5f5f5;
    --color-palette-black-800-a: rgba(0, 0, 0, 0.85);
    --color-palette-black-800: #262626;
    --color-palette-black-900: #161616;
    --color-palette-blue-200-a: rgba(53, 125, 225, 0.12);
    --color-palette-blue-500-a: rgba(53, 125, 225, 0.5);
    --color-palette-blue-dark: #3466ad;
    --color-palette-blue-grayish-30-l: #404c59;
    --color-palette-blue-grayish-90-l: #dae5f1;
    --color-palette-blue-grayish-95-l: #edf2f8;
    --color-palette-blue-grayish-98-l: #f8fafc;
    --color-palette-blue-light: #c9defb;
    --color-palette-blue-mild: #72a8f3;
    --color-palette-blue-normal: #357de1;
    --color-palette-blue-pale: #ecf3fd;
    --color-palette-green-dark: #2b7d64;
    --color-palette-green-light: #c2eadf;
    --color-palette-green-mild: #5cc7a6;
    --color-palette-green-normal: #1c9c75;
    --color-palette-green-pale: #edf7f4;
    --color-palette-orange-dark: #bd4f28;
    --color-palette-orange-light: #fcdace;
    --color-palette-orange-normal: #e67046;
    --color-palette-orange-pale: #fdf1ed;
    --color-palette-purple-normal: #d471e4;
    --color-palette-purple-pale: #fcf6fe;
    --color-palette-red-dark: #bd4e4c;
    --color-palette-red-light: #fad7d6;
    --color-palette-red-mild: #f39796;
    --color-palette-red-normal: #e36563;
    --color-palette-red-pale: #fdeded;
    --color-palette-white-100-a: rgba(255, 255, 255, 0.06);
    --color-palette-white-1000: #ffffff;
    --color-palette-white-200-a: rgba(255, 255, 255, 0.12);
    --color-palette-white-300-a: rgba(255, 255, 255, 0.25);
    --color-palette-white-500-a: rgba(255, 255, 255, 0.5);
    --color-palette-white-800-a: rgba(255, 255, 255, 0.75);
    --color-palette-yellow-500-a: rgba(255, 183, 44, 0.5);
    --color-palette-yellow-dark: #faa500;
    --color-palette-yellow-light: #ffedca;
    --color-palette-yellow-normal: #ffb72c;
    --color-palette-yellow-pale: #fff7eb;
}

.prisma {
    --spacing-2: 2px;
    --spacing-4: 4px;
    --spacing-8: 8px;
    --spacing-12: 12px;
    --spacing-16: 16px;
    --spacing-20: 20px;
    --spacing-24: 24px;
    --spacing-32: 32px;
    --spacing-36: 36px;
    --spacing-40: 40px;
    --spacing-48: 48px;
    --spacing-64: 64px;
    --spacing-80: 80px;
    --spacing-120: 120px;
}

.prisma_theme_dark {
    --color-accent-critical: #e36563;
    --color-accent-main-a: #357de1;
    --color-accent-main-b: #3466ad;
    --color-accent-neutral: #ffffff;
    --color-accent-notice: #e67046;
    --color-accent-success: #1c9c75;
    --color-accent-warning: #ffb72c;
    --color-background-alternate: #262626;
    --color-background-default: #161616;
    --color-background-glassy-a: rgba(255, 255, 255, 0.06);
    --color-background-glassy-b: rgba(255, 255, 255, 0.12);
    --color-background-glassy-c: rgba(255, 255, 255, 0.25);
    --color-background-highlighted: #404959;
    --color-background-overlay: rgba(0, 0, 0, 0.85);
    --color-background-stroke: #2e2e2e;
    --color-content-inverted-primary: rgba(0, 0, 0, 0.85);
    --color-content-inverted-secondary: rgba(0, 0, 0, 0.5);
    --color-content-primary: #f7f7f7;
    --color-content-secondary: rgba(255, 255, 255, 0.5);
    --color-content-tertiary: rgba(255, 255, 255, 0.25);
}

.prisma_theme_light {
    --color-accent-critical: #e36563;
    --color-accent-main-a: #357de1;
    --color-accent-main-b: #3466ad;
    --color-accent-neutral: #262626;
    --color-accent-notice: #e67046;
    --color-accent-success: #1c9c75;
    --color-accent-warning: #ffb72c;
    --color-background-alternate: #F2F1EE;
    --color-background-default: #ffffff;
    --color-background-glassy-a: rgba(0, 0, 0, 0.06);
    --color-background-glassy-b: rgba(0, 0, 0, 0.12);
    --color-background-glassy-c: rgba(0, 0, 0, 0.25);
    --color-background-highlighted: #edf1f8;
    --color-background-overlay: rgba(0, 0, 0, 0.25);
    --color-background-stroke: #e0e0e0;
    --color-content-inverted-primary: #ffffff;
    --color-content-inverted-secondary: rgba(255, 255, 255, 0.5);
    --color-content-primary: rgba(0, 0, 0, 0.85);
    --color-content-secondary: rgba(0, 0, 0, 0.5);
    --color-content-tertiary: rgba(0, 0, 0, 0.25);
}
