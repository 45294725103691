.icon {
    display: inline-block;

    text-align: center;

    background: 50% no-repeat;
    background-size: contain;
}

/* для правильного позиционирования иконки относительно baseline */
.icon::after {
    content: '\00A0';
}

.icon_hidden {
    display: none;
}
