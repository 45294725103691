.error__content {
    width: 100%;
    max-width: 480px;

    text-align: center;
    color: var(--color-palette-black-1000);
}

.error__image {
    margin: auto;
}

.error__title {
    max-width: 800px;
    font: var(--font-sans-header-32-default);
    margin-top: var(--spacing-32);
}

.error__message {
    font: var(--font-sans-body-18-regular-paragraph);
    margin-top: var(--spacing-16);
    color: var(--color-content-secondary);
}

.error__button-container {
    margin-top: var(--spacing-48);
}

.error__button {
    background-color: var(--color-accent-neutral);
    color: var(--color-content-inverted-primary);
    outline: none;
    text-decoration: none;
    font: var(--font-sans-body-16-medium-ui);
    padding: 14px var(--spacing-24);
    border-radius: var(--border-radius-12);
    min-width: 176px;
    display: inline-block;

    transition: transform 0.1s ease-in-out, background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.error__button:active {
    transform: scale(0.96);
}

.error__button:hover {
    background-color: var(--color-palette-black-1000);
}

.error__button_disabled {
    color: var(--color-palette-black-300-a);
    background-color: var(--color-palette-black-100-a);
    pointer-events: none;
}
