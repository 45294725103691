body,
html,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-background-alternate);
}

.kc-app__main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-background-alternate);
    padding: var(--spacing-32) var(--spacing-24) var(--spacing-80) var(--spacing-24);
    box-sizing: border-box;
}

.kc-app__main_black {
    background-color: var(--color-palette-black-1000);
}

.kc-app__page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 12vh;
}

.kc-app__page * {
    box-sizing: border-box;
}

/* var(--media-mobile-l) */
@media (max-width: 539px) {
    .kc-app__main {
        padding-top: var(--spacing-24);
    }
}
