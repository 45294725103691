.checkbox-wrapper {
    margin: var(--spacing-24) 0 var(--spacing-32);
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
}

.checkbox__input {
    position: absolute;
    opacity: 0;
}

.checkbox__control {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    position: relative;

    box-sizing: border-box;

    width: var(--spacing-24);
    height: var(--spacing-24);

    opacity: 1;

    border: 1.5px solid var(--color-accent-neutral);
    border-radius: var(--border-radius-6);

    background-color: var(--color-background-default);
}

.checkbox:not(.checkbox_disabled) .checkbox__control {
    cursor: pointer;
}

.checkbox:not(.checkbox_disabled) .checkbox__control:hover {
    background-color: var(--color-background-stroke);
    border-color: var(--color-palette-black-1000);
}

.checkbox__input:checked ~ .checkbox__control {
    background-color: var(--color-content-primary);
}

.checkbox:not(.checkbox_disabled) .checkbox__input:checked ~ .checkbox__control:hover {
    background-color: var(--color-palette-black-1000);
}

.checkbox__tick {
    display: block;
    position: relative;

    color: var(--color-palette-white-1000);

    visibility: hidden;

    transition: 0.2s;
    transform: scale(0);
}

.checkbox__input:checked ~ .checkbox__control .checkbox__tick {
    visibility: visible;

    transform: scale(1);
}

.checkbox:not(.checkbox_disabled) .checkbox__input:checked ~ .checkbox__control:hover .checkbox__tick {
    color: var(--color-palette-black-300);
}

.checkbox__label {
    margin-left: var(--spacing-12);
    white-space: nowrap;

    font: var(--font-sans-body-16-regular-ui);
}

.checkbox__message-slidedown {
    overflow: hidden;

    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 0.3s;
}

.checkbox__message_error {
    margin-top: var(--spacing-8);

    color: var(--color-accent-critical);
    font: var(--font-sans-caption-14-regular);
    text-align: center;
}
