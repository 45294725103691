.support-chat__mount {
    --trigger-button-size: 40px;

    position: fixed;
    bottom: calc(var(--spacing-16) + var(--spacing-16) + var(--trigger-button-size));
    right: var(--spacing-16);

    width: 360px;
    height: 500px;

    border-radius: var(--border-radius-16);

    overflow: hidden;

    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    display: flex;

    pointer-events: none;
}
