.password-strength-slidedown {
    overflow: hidden;

    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 0.3s;
}

.password-strength {
    padding: var(--spacing-8) 0;

    --animation-duration: 0.3s;
    --animation-easing: linear;
}

.password-strength__meter {
    margin-bottom: var(--spacing-8);
    color: var(--color-accent-notice);
    transition: color var(--animation-duration) var(--animation-easing);
}

.password-strength__meter_success {
    color: var(--color-accent-success);
}

.password-strength__meter-title {
    font: var(--font-sans-caption-14-medium);
    margin-bottom: var(--spacing-8);
}

.password-strength__meter-bar {
    position: relative;
    height: 4px;
    border-radius: var(--border-radius-20);
    background: var(--color-background-glassy-a);
}

.password-strength__meter-bar-level {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: var(--border-radius-20);
    background-color: currentColor;
    transition: width var(--animation-duration) var(--animation-easing);
}

.password-strength__hint {
    font: var(--font-sans-caption-14-regular);
    color: var(--color-content-secondary);
}
