.support-chat__trigger_unread:after {
    content: '';
    display: block;

    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #e96e3a;

    top: 0;
    right: 0;
}
