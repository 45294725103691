.magic-link-confirm__button-container {
    margin-top: var(--spacing-32);
}

.magic-link-confirm__button {
    background-color: var(--color-accent-neutral);
    color: var(--color-content-inverted-primary);
    outline: none;
    text-decoration: none;
    font: var(--font-sans-body-16-medium-ui);
    padding: 14px var(--spacing-24);
    border-radius: var(--border-radius-12);
    display: inline-block;
    min-width: 176px;
    text-align: center;

    transition: transform 0.1s ease-in-out, background-color 0.15s ease-in-out;
}

.magic-link-confirm__button:active {
    transform: scale(0.96);
}

.magic-link-confirm__button:hover {
    background-color: var(--color-palette-black-1000);
}

.magic-link-confirm__content {
    max-width: 336px;
    text-align: center;
}

.magic-link-confirm__title {
    color: var(--color-content-primary);
    font: var(--font-sans-body-16-regular-paragraph);
}

.magic-link-confirm__title_email {
    font: var(--font-sans-body-16-medium-paragraph);
    overflow-wrap: break-word;
}

@media screen and (max-width: 539px) {
    .magic-link-confirm__content {
        width: auto;
    }
}
