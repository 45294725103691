.message-toast {
    position: fixed;
    max-width: 336px;
    top: var(--spacing-24);
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    margin: auto;
    font: var(--font-sans-body-16-medium-ui);
}

.message-toast__content {
    position: relative;
    padding: 10px var(--spacing-16);
    padding-right: var(--spacing-40);
    background-color: var(--color-accent-warning);
    border-radius: var(--border-radius-10);
    text-align: left;
}

.message-toast__close-button {
    --size-icon: 24px;

    display: flex;
    padding: 0;
    position: absolute;
    top: var(--spacing-8);
    left: calc(100% - var(--size-icon) - var(--spacing-8));

    outline: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

/* var(--media-mobile-l) */
@media screen and (max-width: 539px) {
    .message-toast {
        top: auto;
        display: block;
        bottom: 0px;
    }

    .message-toast__content {
        border-radius: 0px;

        padding-top: var(--spacing-40);
        padding-right: var(--spacing-20);

        text-align: center;
    }

    .message-toast__close-button {
        top: var(--spacing-12);
        left: calc(50% - var(--size-icon) / 2);
    }
}
