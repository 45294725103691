.password-strength-slidedown {
    overflow: hidden;

    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 0.3s;
}

.password-input__strength-meter {
    margin-top: var(--spacing-8);
}
