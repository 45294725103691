.login-verify-email__text-block {
    text-align: center;
    margin-bottom: var(--spacing-24);
}

.login-verify-email__title {
    color: var(--color-content-primary);
    font: var(--font-sans-body-16-regular-paragraph);
}

.login-verify-email__title_email {
    font: var(--font-sans-body-16-medium-paragraph);
    overflow-wrap: break-word;
}

.login-verify-email__note {
    color: var(--color-content-secondary);
    font: var(--font-sans-body-16-regular-paragraph);
    white-space: break-spaces;
}

.login-verify-email__action-text {
    all: unset;
}

.login-verify-email__note_accent {
    text-decoration: underline;
    color: var(--color-content-primary);
    font: var(--font-sans-body-16-medium-paragraph);
    cursor: pointer;
    text-decoration-color: var(--color-background-glassy-b);
}

.login-verify-email__note_accent:hover {
    text-decoration-color: inherit;
}

.login-verify-email__text-block_withot-margin {
    margin: 0;
}
